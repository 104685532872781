import { all, call, put, takeEvery } from 'redux-saga/effects';
import { 
    GET_ROLE_LIST, 
    SET_ROLE_LIST, 
    // SET_USER_AUTH, 
    // GET_USER_AUTH, 
    SET_USER_APP_AUTH,
    GET_USER_APP_AUTH,
} from '../../constants/roleAuthority';
import { 
    manageRoleLists, 
    // manageUserGetUserAuth,
    manageAppMenu,
} from '../../api/api';

function* getRoleList(action: any) {
    const { data } = yield call(manageRoleLists, action.payload);
    yield put({ type: SET_ROLE_LIST, payload: data });
}

// function* getUserAuth(action: any) {
//     const { data } = yield call(manageUserGetUserAuth, action.payload);
//     yield put({ type: SET_USER_AUTH, payload: data});
// }

function* getUserAppAuth() {
    const { data } = yield call(manageAppMenu);
    yield put({ type: SET_USER_APP_AUTH, payload: data})
}

export default function* watchRole() {
    yield all([
        takeEvery(GET_ROLE_LIST, getRoleList),
        // takeEvery(GET_USER_AUTH, getUserAuth),
        takeEvery(GET_USER_APP_AUTH, getUserAppAuth),
    ])
}
