import { connect } from 'react-redux';
import { SET_USERINFO } from '../constants/userInfo';
import { UserInfo, Dispatch } from '../interface';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    setUserInfo: (payload: UserInfo | null) => {
        dispatch({type: SET_USERINFO, payload})
    }
})

const connectDecorator: Function = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default connectDecorator;
