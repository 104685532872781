import React from 'react';
import { Row, Col, Form, Select, Button, Icon, Input, message, Upload, Spin, Avatar } from 'antd';
import './userInfo.scss';
import connectDecorator, { ConnectProps } from '../../connect/changePassword';
import { FormComponentProps } from '../../interface';
import { require, password, pattern } from '../../utils/rules';
import { manageUpdatepwd } from '../../api/api';
import { resCode } from '../../utils/code';

interface IState {
    loading: boolean;
}

interface IProps extends ConnectProps {
};
interface IProps extends FormComponentProps {
    close: () => void;
    save: () => void;
};
class UserInfoCenter extends React.Component<IProps, IState> {
    state = {
        loading: false
    }
    render() {
        const { form: { getFieldDecorator } } = this.props;
        const { loading } = this.state
        const formLayout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            }
        }
        return (
            <Spin spinning={loading}>
                <Row type='flex' justify='space-between' className='userinfo-header'>
                    <Col >
                        <div>个人中心</div>
                    </Col>
                </Row>
                <Row className='userInfo-content'>
                    <Row className='user-info'>
                        账户设置
                    </Row>
                </Row>
                <Row type='flex' justify='center' className='user-box'>
                    <Form {...formLayout}
                        style={{
                            marginTop: '10px'
                        }}>
                        <Row>
                            <Row type='flex' justify='center' style={{ marginTop: 50, marginBottom: 25 }}>
                                <Avatar size={100} icon="user" style={{ marginLeft: 120 }} />
                            </Row>
                            <Row>
                                <Form.Item label="用户名">
                                    {getFieldDecorator('username', {
                                        rules: [
                                            require('该项为必填项'),
                                        ],
                                        validateFirst: true,
                                        validateTrigger: 'onBlur'
                                    })(<Input
                                        disabled
                                        style={{
                                            // width: 300,
                                            // marginLeft: '20px'
                                            height: 40
                                        }}
                                    />)}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item label="角色">
                                    {getFieldDecorator('role_name', {
                                        rules: [
                                            require('该项为必填项'),
                                        ],
                                        validateFirst: true,
                                        validateTrigger: 'onBlur'
                                    })(<Input
                                        disabled
                                        style={{
                                            // width: 300,
                                            // marginLeft: '20px',
                                            height: 40
                                        }}
                                    />)}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item label="旧密码">
                                    {getFieldDecorator('old_pwd', {
                                        rules: [
                                            require('该项为必填项'),
                                            pattern('请输入正确的密码', /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/),
                                        ],
                                        validateFirst: true,
                                        validateTrigger: 'onBlur'
                                    })(<Input
                                        type='password'
                                        placeholder='请输入旧密码'
                                        style={{
                                            // width: 300,
                                            // marginLeft: '20px',
                                            height: 40
                                        }}
                                    />)}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item label="新密码">
                                    {getFieldDecorator('new_pwd', {
                                        rules: [
                                            require('该项为必填项'),
                                            pattern('密码格式由8-16位数字和字母组成', /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/),
                                        ],
                                        validateFirst: true,
                                        validateTrigger: 'onBlur'
                                    })(<Input
                                        type='password'
                                        placeholder='请输入新密码'
                                        style={{
                                            // width: 300,
                                            // marginLeft: '20px',
                                            height: 40
                                        }}
                                    />)}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Form.Item label="确认新密码">
                                    {getFieldDecorator('pwd_conf', {
                                        rules: [
                                            require('该项为必填项'),
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (this.props.form.getFieldValue('new_pwd') !== value) {
                                                        return callback(new Error('二次密码不一致'));
                                                    }
                                                    callback();
                                                }
                                            }
                                        ],
                                        validateFirst: true,
                                        validateTrigger: 'onBlur'
                                    })(<Input
                                        type='password'
                                        placeholder='请输入新密码'
                                        style={{
                                            // width: 300,
                                            // marginLeft: '20px',
                                            height: 40
                                        }}
                                    />)}
                                </Form.Item>
                            </Row>
                            <Row
                                style={{
                                    width: 400,
                                    height: 50,
                                    marginBottom: 100,
                                    textAlign: "right"
                                }}
                            >
                                <Button
                                    style={{
                                        width: 266,
                                        height: 40
                                    }}
                                    onClick={this.submit}
                                    type="primary">
                                    提交
                                </Button>
                            </Row>
                        </Row>
                    </Form>
                </Row>
            </Spin>
        )
    }
    componentDidMount() {
        if (localStorage.getItem('tobacco-user')) {
            let username: string | null = localStorage.getItem('tobacco-user') || null;
            if (username) {
                this.props.form.setFieldsValue({
                    username: JSON.parse(username).username,
                    role_name: JSON.parse(username).role_name
                });
            }
        }
    }
    //退出
    onClose = () => {
        this.props.clearUserInfo();
    }
    submit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loading: true,
                }, () => {
                    manageUpdatepwd({
                        ...values,
                    })
                        .then((res: any) => {
                            if (res.code === resCode.success) {
                                message.success('修改成功， 请重新登录', .5, this.onClose)
                                this.props.form.resetFields();
                            } else {
                                message.warning(res.message);
                            }
                            this.setState({
                                loading: false,
                            })
                        })
                })
            }
        })
    }
}

export default connectDecorator(Form.create<IProps>()(UserInfoCenter));