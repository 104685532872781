import React from 'react';
import { Layout, Spin } from 'antd';
import MyHeader from './Header';
import MyMenu from './Menu';
import MyContent from './Content';
import { UserInfo, MenuItem } from '../../interface'
import connectDecorator from '../../connect/Layout';
import IndexFunc from '../Home';
const { Sider, Header, Content } = Layout;

interface IProps {
    userInfo: UserInfo;
    menu: {
        appMenu: MenuItem[] | null;
        pcMenu: MenuItem[] | null;
        nonYcGoodsMenu: MenuItem[] | null;
        nonYcGoodAppMenu: MenuItem[] | null;
    };
    getMenu: () => void;
    clearUserInfo: () => void;
    getAppMenu: () => void;
    getNonYcGoodsMenu: () => void;
    getNonYcGoodAppMenu: () => void;
}
interface IState {
    loading: boolean;
}
class MainLayout extends React.Component<IProps, IState> {
    readonly state = {
        loading: false,
    } as IState
    componentDidMount() {
        const { menu, getMenu, getAppMenu, getNonYcGoodsMenu, getNonYcGoodAppMenu } = this.props;
        if (!menu.pcMenu) {
            getMenu();
        }
        if (!menu.appMenu) {
            getAppMenu();
        }
        if (!menu.nonYcGoodsMenu) {
            getNonYcGoodsMenu();
        }
        if (!menu.nonYcGoodAppMenu) {
            getNonYcGoodAppMenu();
        }
    }
    render() {
        const { menu, userInfo, clearUserInfo } = this.props;
        const { pcMenu } = menu;
        return (
            <Layout style={{
                height: '100vh'
            }}>
                <Layout>
                    <Header style={{
                        height: '60px',
                        background: '#1AA86B',
                        padding: '0 20px',
                        boxSizing: 'border-box'
                    }}>
                        <MyHeader userInfo={userInfo} clearUserInfo={clearUserInfo} />
                    </Header>
                    <Spin spinning={menu ? false : true}>
                        <Layout>
                            <Sider style={{
                                width: '180px',
                                background: '#fff'
                            }}>
                                <MyMenu menu={pcMenu} />
                            </Sider>
                            <Content>
                                <MyContent menu={pcMenu} />
                            </Content>
                        </Layout>
                    </Spin>
                </Layout>
            </Layout>
        )
    }
}

export default connectDecorator(MainLayout);