import React from 'react';
import {
    Row,
    Form,
    Button,
    Input,
    message,
    Spin,
} from 'antd';
import connectDecorator, { ConnectProps } from '../../connect/changePassword';
import { FormComponentProps } from '../../interface';
import { require, password, passwordRepeat } from '../../utils/rules';
import { resCode } from '../../utils/code';
import { manageUpdatepwd } from '../../api/api';
/*
* State限制
* */
interface IState {
    loading: boolean;
}
/*
* Props限制
* */
interface IProps extends ConnectProps { };
interface IProps extends FormComponentProps {
    close: () => void;
    save: () => void;
};
class Add extends React.Component<IProps, IState> {
    readonly state: IState = {
        loading: false,
    }
    render() {
        const { form: { getFieldDecorator } } = this.props;
        const { loading } = this.state;
        const formLayout = {
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 19,
            }
        }
        return (<>
            <Spin spinning={loading}>
                <Form
                    {...formLayout}
                    style={{
                        marginTop: '10px'
                    }}>
                    <Row>
                        <Row>
                            <Form.Item label="旧密码">
                                {getFieldDecorator('old_pwd', {
                                    rules: [
                                        require('该项为必填项'),
                                        password(),
                                    ],
                                    validateFirst: true,
                                    validateTrigger: 'onBlur'
                                })(<Input
                                    type='password'
                                    placeholder='请输入旧密码'
                                    style={{
                                        width: 300,
                                        marginLeft: '20px'
                                    }} />)}
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item label="新密码">
                                {getFieldDecorator('new_pwd', {
                                    rules: [
                                        require('该项为必填项'),
                                        password(),
                                    ],
                                    validateFirst: true,
                                    validateTrigger: 'onBlur'
                                })(<Input
                                    type='password'
                                    placeholder='请输入新密码'
                                    style={{
                                        width: 300,
                                        marginLeft: '20px'
                                    }} />)}
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item label="确认新密码">
                                {getFieldDecorator('pwd_conf', {
                                    rules: [
                                        require('该项为必填项'),
                                        {
                                            validator: (rule, value, callback) => {
                                                if (this.props.form.getFieldValue('new_pwd') !== value) {
                                                    return callback(new Error('二次密码不一致'));
                                                }
                                                callback();
                                            }
                                        }
                                    ],
                                    validateFirst: true,
                                    validateTrigger: 'onBlur'
                                })(<Input
                                    type='password'
                                    placeholder='请输入新密码'
                                    style={{
                                        width: 300,
                                        marginLeft: '20px'
                                    }} />)}
                            </Form.Item>
                        </Row>
                        <Row
                            style={{
                                paddingLeft: '20px',
                                paddingTop: '10px',
                                textAlign: 'right',
                                borderTop: '1px solid #f5f5f5'
                            }}>
                            <Button
                                onClick={this.close}>
                                取消
                            </Button>
                            <Button
                                style={{
                                    marginLeft: '20px'
                                }}
                                ghost={true}
                                onClick={this.submit}
                                type="primary">
                                保存
                            </Button>
                        </Row>
                    </Row>
                </Form>
            </Spin>
        </>)
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }
    submit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loading: true,
                }, () => {
                    manageUpdatepwd({
                        ...values,
                    })
                        .then((res: any) => {
                            if (res.code === resCode.success) {
                                message.success('修改成功， 请重新登录');
                                this.props.form.resetFields();
                                this.props.save();
                                this.props.clearUserInfo();
                            } else {
                                message.warning(res.message);
                            }
                            this.setState({
                                loading: false,
                            })
                        })
                })
            }
        })
    }
    close = () => {
        this.props.close();
        this.props.form.resetFields();
    }
}

export default connectDecorator(Form.create<IProps>()(Add));