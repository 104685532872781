import React from 'react';
import { message } from 'antd';
import { MenuItem, AuthDataToCustom, RouteMeta, RoutesType, selectItem } from '../interface';
import { localUserKey } from '@/constants/common';
import _ from 'lodash';

/**
 * 
 * @param _this 接收一个组件实例
 * @param field 要改变的字段名
 * @param close 是否是关闭 默认为打开
 */
export const changeVisible = (_this: React.Component<any, { visible: object }>, field: string, close?: boolean, callback: () => void = () => { }) => {
    _this.setState(({ visible }) => ({
        visible: {
            ...visible,
            [field]: close ? false : true,
        }
    }), callback)
}

export const changeLoading = (_this: React.Component<any, { loading: boolean }>, loading: boolean, callback: () => void = () => { }) => {
    _this.setState({
        loading,
    }, callback)
}

/**
 * @description 接收UserAuth， 返回计算过后的值
 * @param origin 接收UserAuth类型数组,
 * @param target 内部递归的状态, 最后的返回值
 * @param count  内部递归的状态
 */
export const authDataToCustom = (origin: MenuItem[] | null): AuthDataToCustom[] => {
    if (!origin) {
        return [];
    }
    let target = [];
    for (let level1 of origin) {
        if (level1._child) {
            let rowLength = level1._child.length;
            for (let level2 of level1._child) {
                target.push({
                    level1: {
                        id: level1.id,
                        row: rowLength,
                        name: level1.menu_name,
                        type: level1.type,
                    },
                    level2: {
                        id: level2.id,
                        name: level2.menu_name,
                        type: level2.type,
                    },
                    level3: level2._child
                        ? level2._child.map((level3) => ({
                            id: level3.id,
                            name: level3.menu_name,
                            type: level3.type,
                        }))
                        : null,
                })
                rowLength = 0;
            }
        } else {
            target.push({
                level1: {
                    id: level1.id,
                    row: 1,
                    name: level1.menu_name,
                    type: level1.type,
                },
                level2: null,
                level3: null,
            })
        }
    }
    return target;
}

/**
 * @description 把对象转换为数组， 主要用于利用object实现去重， 然后实现map方法， 返回一个数组。
 * @param target 接收任意对象
 * @param callback 接收一个回调， 实现原理和数组的filter方法一样。
 * @return 返回一个数组对象
 */
export function ObjToArr(target: { [key: string]: any }, callback: (key?: string, item?: any) => boolean): string[] {
    const ids: string[] = [];
    for (let key of Object.keys(target)) {
        callback(key, target[key]) && ids.push(key);
    }
    return ids;
}

/**
 * @description 接收组件的路由元信息
 * @param routeMeta 路由的元信息
 * @returens 返回固定的面包屑导航的数组
 */
export function getBreadcrumbData(routeMeta: RouteMeta) {
    return [
        {
            name: routeMeta.meta.parent.name,
            path: routeMeta.meta.parent.path,
        },
        {
            name: routeMeta.name,
            path: routeMeta.path,
        }
    ]
}

/**
 * @description 接收一个下载地址， 自动下载
 * @param url 接收要下载的地址
 */
export function exportDownload(url: string) {
    const form = document.createElement("form");
    form.setAttribute('action', url);
    form.style.display = 'none';
    document.body.appendChild(form);
    form.submit();
}


export function filterAreaId(areaArr: any[]): {
    provinceIds: number[],
    cityIds: number[],
    areaIds: number[],
} {
    let provinceSet = new Set();
    let citySet = new Set();
    let areaSet = new Set();
    for (let item of areaArr) {
        item.province_id && provinceSet.add(item.province_id);
        item.city_id && citySet.add(item.city_id);
        item.area_id && areaSet.add(item.area_id);
    }
    return {
        provinceIds: [...provinceSet] as number[],
        cityIds: [...citySet] as number[],
        areaIds: [...areaSet] as number[],
    };
}

export function getBtnMenu(menu: any[]): any {
    let arr: any = [];
    menu.forEach((item: any) => {
        if (item.type === 3) {
            arr.push(item.menu_url);
        }
        if (item._child) {
            arr = [
                ...getBtnMenu(item._child),
                ...arr
            ];
        }
    })
    return arr.filter(Boolean);
}

/**
 * 把对象转换成数组对象
 * @param obj 要改变的对象
 */
export function objectToArray(obj: any) {
    const newObj: any = {};
    for (let item in obj) {
        newObj[item] = [];
        for (let itemchild in obj[item]) {
            newObj[item].push({
                key: itemchild,
                name: obj[item][itemchild]
            })
        }
    }
    return newObj
}
/** 
 * @description 上传图片之前的判断
 * @param file 
 */
export function beforeUploadImg(file: any) {
    const type = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!type) {
        message.error('格式不正确， 请上传png或jpg格式图片');
    }

    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error('图片过大， 请上传小于5M的图片');
    }
    return type && isLt2M;
}


export function filterPowerMenu(allPowerArr: RoutesType[], havePowerArr: any[] | null, lowerLevel?: boolean): RoutesType[] {
    if (!havePowerArr) {
        return [];
    }
    const newArr: any[] = allPowerArr.filter((aRes: any) => {
        if (!aRes.children && !lowerLevel) {
            return aRes;
        }
        let flag: boolean = false;
        let hResChildren: any[] = [];
        havePowerArr.forEach((hRes: any) => {
            if (hRes.code === aRes.power) {
                flag = true;
                hResChildren = hRes._child;
            }
        });
        if (!flag) {
            return;
        }
        if (aRes.children && hResChildren) {
            aRes.children = filterPowerMenu(aRes.children, hResChildren, true);
        } else if (!aRes.isParent) {
            aRes.children = [];
        }
        return aRes;
    });
    return newArr;
}
/**
 * @description 深克隆路由数组
 * @param target 传入一个路由数组
 * @returns 传入的参数的克隆版
 */
export function darkClone(routes: RoutesType[]): RoutesType[] {
    return routes.map((item: any) => {
        if (item.children) {
            return {
                ...item,
                children: darkClone(item.children)
            }
        } else {
            return {
                ...item
            }
        }
    })
}

// export function filterPowerRoute(allPowerArr: RoutesType[], havePowerArr: any[] | null): RoutesType[] {
//     if (!havePowerArr) {
//         return [];
//     }
//     // const newArr: any[] = [];
//     const newArr: any[] = allPowerArr.filter((aRes: any) => {
//         if (!aRes.children) {
//             return aRes;
//         }
//         let flag: boolean = false;
//         let hResChildren: any[] = [];
//         havePowerArr.forEach((hRes: any) => {
//             if (hRes.power === aRes.code) {
//                 flag = true;
//                 hResChildren = hRes._child;
//             }
//         });
//         if (!flag) {
//             return;
//         }
//         if (aRes.children && hResChildren) {
//             aRes.children = aRes.children.filter((iAres: any) => {
//             })
//             // aRes.children = filterPowerRoute(aRes.children, hResChildren);
//         } else if (!aRes.isParent) {
//             aRes.children = [];
//         }
//         return aRes;
//     });
//     return newArr;
// }

export const convertAxiosData = (innerData: any) => ({
    data: { ...innerData }
});

/**
 * @method 判断登录用户是否为区县级用户
 */
export const checkCountryAccount = (): boolean => {
    const user = JSON.parse(localStorage.getItem(localUserKey) || '[]');
    if (!_.isEmpty(user)) {
        if (user.district) {
            const { province_id, city_id, area_id } = user.district[0];
            return !!province_id && !!city_id && !!area_id;
        }
    }
    return false;
};

/**
 * @method 判断登录用户是否为超管
 */
export const checkAdminAccount = (): boolean => {
    const user = JSON.parse(localStorage.getItem(localUserKey) || '[]');
    if (!_.isEmpty(user)) {
        if (user.role_id === 1) return true;
    }
    return false;
};

export const getProtocol = (url: string) => {
    if (url.indexOf('https://') === 0) {
        return 'https://';
    } else if (url.indexOf('http://') === 0) {
        return 'http://';
    } else {
        return '';
    }
};


export const loopCheckTree = (tree: Array<selectItem>): string[] => {
    let parentId: string[] = [];
    const getParentId = (tree: Array<selectItem>) => {
        tree.forEach(i => {
            if (i.isAllAreaChecked) parentId.push(i.id);
            if (i.child) getParentId(i.child);
        });
    }
    getParentId(tree);
    return parentId;
};

// 防抖函数
export function debounce(fn: any, delay = 500) {
    let timer: any = null
    return function(this: any, ...args: any) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(this, args)
            timer = null
        }, delay)
    }
} 

// 节流函数
export function throttle(fn: any, delay = 500, immediate?: boolean) {
    let timer: any = null
    return function(this: any, ...args: any) {
        if (timer) return 
        if (immediate) fn.apply(this, args)
        timer = setTimeout(() => {
            if (!immediate) fn.apply(this, args)
            timer = null
        }, delay)
    }
}