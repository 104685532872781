import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './style/common.scss';
import BeforeRouter from './router/BeforeRouter';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import rootSaga from './saga';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import './style/iconfont/iconfont';
import './style/iconfont/iconfont.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { webOpen } from './api/api';

moment.locale('zh-cn');

const sagaMiddleware = createSagaMiddleware();
export const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
webOpen({ browser: window.clientInformation.appVersion })
const App = () => {
    return (
        <Provider store={store}>
            <LocaleProvider locale={zh_CN}>
                <BeforeRouter />
            </LocaleProvider>
        </Provider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'));
