import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
    return <Spin 
                style={{
                    width: '100%',
                    paddingTop: '180px'
                }}
                size="large" />;
}

export default Loading;