import { all } from 'redux-saga/effects';
import watchMenu from './menu';
import watchRole from './roleAuthority';
import watchBusiness from './business';
import watchHome from './home';


export default function* rootSaga() {
    yield all([
        watchMenu(),
        watchHome(),
        watchRole(),
        watchBusiness(),
    ])
}