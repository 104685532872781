import React, { CSSProperties } from 'react';
import { Row, Card } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { asyncRoutes } from '../../router/routes';
import { RoutesType, MenuItem } from '../../interface';
// import { filterPowerRoute } from '../../utils/util';
import NoPower from '../../components/noPower';
import UserInfoCenter from '../userInfoCenter';
import UnmonutState from '../../components/UnmonutState'
import IndexFunc from '../Home';

interface IProps {
    menu: MenuItem[] | null;
}
const style: CSSProperties = {
    height: 'calc(100vh - 80px)',
    overflow: 'auto'
};

@UnmonutState
class MyContent extends React.Component<IProps> {
    render() {
        console.log(111);
        return (
            <Row style={{
                padding: '20px 20px 0px'
            }}>
                <>
                    <Switch>
                        {
                            asyncRoutes.length > 0
                                ? this.asyncRoutes(asyncRoutes)
                                : <>
                                    <Route exact={true} path={'/noPower'} component={NoPower} />
                                    <Redirect to={'/noPower'} />
                                </>
                        }
                        <div style={style}>
                            <Route exact={true} path={'/index'} component={IndexFunc} />
                            {asyncRoutes[0] && <Redirect to={asyncRoutes[0].path} />}
                        </div>
                        <Route exact={true} path={'/userInfoCenter'} component={UserInfoCenter} />
                    </Switch>
                </>
            </Row>
        )
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        }
    }
    /**
     * @description 循环遍历出route数组
     * @returens 返回一个平级的route数组结构
     */
    asyncRoutes = (routes: RoutesType[]): React.ReactNode[] => {
        let newRoutes: React.ReactNode[] = [];
        routes.forEach((item) => {
            const Component = item.component;
            if (Component) {
                newRoutes.push(
                        <Route key={item.path} path={item.path} render={(props) =>
                            <Card style={style}>
                                <Component routeMeta={item} menu={this.props.menu}  {...props} />
                            </Card>}
                        />
                )
            }
            if (item.children) {
                newRoutes = [
                    ...newRoutes,
                    ...this.asyncRoutes(item.children)
                ]
            }
        })
        return newRoutes;
    }
}

export default MyContent;