import { combineReducers } from 'redux';
import userInfo from './userInfo';
import menu from './menu';
import roleAuthority from './roleAuthority';
import inforField from './inforField';
import home from './home';

const reducers = combineReducers({
    userInfo,
    menu,
    home,
    roleAuthority,
    inforField,
});

export default reducers;