/**
 * @Author: EthanWong
 * @Date: 2020/06/11
 */
import { call, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import { queryHomeNotificationList, queryIndexStatistics } from '@/api/api';
import { EnumResponse } from '@/constants/response';
import {
    REQUEST_STATISTICS,
    RECEIVE_STATISTICS,
    RECEIVE_NOTIFICATION,
    REQUEST_NOTIFICATION,
    UPDATE_NOTIFICATION,
} from '../../constants/home';
import { message } from 'antd';
import { AnyAction } from 'redux';

function* getIndexStatistics() {
    const { code, message: msg, data } = yield call(queryIndexStatistics);
    if (code === EnumResponse.success) {
        yield put({ type: RECEIVE_STATISTICS, payload: data });
    } else {
        message.warning(msg);
    }
}

function* getIndexNotification(action: AnyAction) {
    const { code, message: msg, data } = yield call(queryHomeNotificationList, action.payload);
    if (code === EnumResponse.success) {
        yield put({ type: RECEIVE_NOTIFICATION, payload: data });
    } else {
        message.warning(msg);
    }
}

function* updateIndexNotification(action: AnyAction) {
    const notification = yield select(state => state.home.notification);
    yield put({ type: RECEIVE_NOTIFICATION, payload: notification });
}

export default function* watchHome() {
    yield all([
        takeEvery(REQUEST_STATISTICS, getIndexStatistics),
        takeEvery(REQUEST_NOTIFICATION, getIndexNotification),
        takeLatest(UPDATE_NOTIFICATION, updateIndexNotification)
    ])
}