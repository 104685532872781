import { UserInfoAction, UserInfoState } from '../../actions/userInfo';
import {
    SET_USERINFO,
    CLEAR_USERINFO
} from '../../constants/userInfo';

const userInfo = (state: UserInfoState = null, action: UserInfoAction) => {
    switch (action.type) {
        case SET_USERINFO:
            if (action.payload) {
                localStorage.setItem('tobacco-user', JSON.stringify(action.payload));
            }
            return action.payload;
        case CLEAR_USERINFO:
                localStorage.removeItem('tobacco-user');
                localStorage.removeItem('tobacco-areaTree');
            return null;
        default: 
            return state;
    }
}

export default userInfo;