import React from 'react';
import { Form } from 'antd';
const { Item } = Form;

interface IProps {
    field?: string;
    form: any;
    rules?: any[];
    label?: string;
    initialValue?: any;
    style?: any;
    formItemLayout?: any
}
class FormItem extends React.Component<IProps> {
    render() {
        const { label, form, field, rules, children, initialValue, style, formItemLayout } = this.props;
        const { getFieldDecorator } = form;
        return (
            <>
                {field ?
                    <Item label={label} style={style} {...formItemLayout && { ...formItemLayout }}>
                        {getFieldDecorator(field, {
                            rules,
                            initialValue: initialValue ? initialValue : '',
                            validateTrigger: 'onBlur',
                        })(
                            children
                        )}
                    </Item>
                    :
                    <Item label={label} style={style} {...formItemLayout && { ...formItemLayout }}>
                        {children}
                    </Item>
                }
            </>
        )
    }
}

export default FormItem;