/**
 * @Author: EthanWong
 * @Date: 2020/06/15
 */

const formatYMDhms = 'YYYY-MM-DD hh:mm:ss';

const localUserKey: string = 'tobacco-user';

export {
    formatYMDhms,
    localUserKey
}