import React from 'react';
import './jcUser.scss'
export default class JcAgreement extends React.Component {
    render() {
        return (
            <div className='box'>
                <div className='title'>精臣隐私保护政策</div>
                <p className='time'>更新时间：2020年1月12日</p>
                <p>
                 &emsp;&emsp;请务必仔细阅读和理解此软件最终用户许可协议（“本《协议》”）中规定的所有权利和限制。<br/>
                <span className='weight'>在安装或使用本“软件”前，您需要仔细阅读（特别是粗体标注的内容）并决定接受或不接受本《协议》的条款。除非或直至您接受本《协议》的全部条款，否则您不得安装或使用本“软件”。您的安装或使用行为即视为您已阅读并同意受本协议全部条款的约束。</span><br/>
                <span>本《协议》是您与武汉精臣智慧标识科技有限公司（简称“精臣”）之间有关本“软件”的法律协议。除非另附单独的最终用户许可协议或使用条件说明，否则本“软件”还包括在您购买、使用本“软件”后由精臣提供的任何本“软件”升级版本、修正程序、修订和补充内容。您应选择与您的终端、系统等相匹配的本软件版本，否则，您可能无法正常使用本软件。</span><br/>
                <span className='weight'>精臣将根据互联网的发展和中华人民共和国有关法律、法规的变化，不断地完善服务质量并依此修改本服务协议。用户的权利以及义务的表述，均以最新的服务协议为准。由于精臣的用户较多，如本协议发生变更，我们将以推送通知或弹窗等方式来通知您，变更后的协议将从通知内规定的生效日期开始适用。若您在本政策修订后继续使用精臣的服务，即表示您已充分阅读、理解并接受了修订后的协议并愿意受修订后的协议的约束。</span><br/>
                <span>一．软件的获取</span><br/>
                <span>1. 您可以从获得精臣合法授权的第三方获取本“软件”。</span><br/>
                <span>2.如果您从未经精臣合法授权的第三方获取本“软件”或与本“软件”名称相同的安装程序，精臣无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。</span><br/>
                <span>二.软件的安装、使用与卸载</span><br/>
                <span>1. 精臣可能为不同的终端、系统等开发了适用于精臣系列产品不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。</span><br/>
                <span>2.下载安装程序后，您需要按照该程序提示的步骤正确安装。</span><br/>
                <span>3.为提供更加优质、安全的服务，在本“软件”安装时精臣可能推荐您安装其他软件，您可以选择安装或不安装。若您选择安装，则您须同意并接受该软件中附带的全部协议（如有）及与该软件相关的全部政策（如有）。精臣对您安装、使用第三方软件的行为不予负责。</span><br/>
                <span>4.如果您不再需要使用本“软件”或者需要安装新版软件，可以自行将本“软件”卸载。</span><br/>
                <span>5.您在账户中设置的昵称、头像、签名、留言等请务必遵守法律法规、公序良俗、社会公德，且不会侵害其他第三方的合法权益，同时，妥善保管用于登录本“软件”的账号、密码，如账号、密码遗失、被盗或被非经您授权的第三方使用从而给您造成损失的，精臣不承担任何责任。</span><br/>
                <span>三．软件的更新</span><br/>
                <span>1.为了增进用户体验、完善服务内容，精臣将不断努力开发本“软件”的新功能，并不时为您提供本“软件”更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。</span><br/>
                <span>2.为了保证服务的安全性和功能的一致性，本“软件”为您默认开启自动升级、更新等功能，即精臣有权不经向您特别通知而对本“软件”进行更新，或者对本“软件”的部分功能效果进行改变或限制。</span><br/>
                <span>3.精臣提供给您的本“软件”的任何替换版本或修改版本或升级版本，将被视为本“软件”的一部分并且要受到本《协议》条款的制约（除非本《协议》被随附本“软件”的替换或修改版本或升级版本的另外一份《协议》取代）。</span><br/>
                <span>4.  如果精臣提供本“软件”的一个替换版本或修改版本或任何升级版本，则您对本“软件”替换版本或修改版本或任何升级版本的使用条件是（a）您接受本“软件”的这类替换版本或修改版本或升级版本以及任何随附的取代《协议》(如有)，并且（b）您对本“软件”的所有先前版本的使用将被终止。</span><br/>
                <span>四．本“软件”的著作权及其他知识产权</span><br/>
                <span>1.您不得去掉本“软件”上的任何版权标识及版权声明。</span><br/>
                <span className='weight'>2.精臣提供的服务内容（包括但不限于文字、照片、图形、图像、图表、声音等）、随附的电子材料的著作权及其他知识产权，均属于精臣所有（属于第三方所有之商标及第三方所有之其他权利除外）。用户不能擅自复制、改编这些内容、或创造与内容有关的衍生产品。</span><br/>
                <span>3.用户通过精臣平台服务上传到精臣软件上可公开获取区域的任何内容（包括但不限于文字、图片、图表、和笔记）的知识产权属于用户或原始权利人所有。用户同意精臣使用、复制、修改、改编、出版、翻译上述内容（整体或部分）并据以创作衍生作品，将上述内容编入当前已知的或以后开发的其他任何形式的作品或产品中。</span><br/>
                <span>五．责任有限</span><br/>
                <span className='weight'>在适用法律所允许的最大范围内，精臣绝不就因使用或不能使用本“软件”所引起的或有关的任何间接的、意外的、直接的、特殊的、惩罚性的或其它任何损害（包括但不限于财产损坏而造成的损害，因利润损失、数据损失、营业中断、计算机瘫痪或故障、商业信息的遗失而造成的损害，因未能履行包括诚信或相当注意在内的任何责任致使隐私泄露而造成的损害，因疏忽而造成的损害，或因任何金钱上的损失或任何其它损失而造成的损害,但因精臣的故意或重大过失造成您的财产损失的除外）承担赔偿责任，即使精臣事先被告知该损害发生的可能性。即使补救措施未能达到预定目的，本损害赔偿排除条款将仍然有效。在适用法律所允许的最大范围内，不论何种情况，精臣在本《协议》任何条款下所承担的全部责任，以您获得本《协议》约定之本“软件”授权而支付的合理款项（如有）为限，如您系免费获得本“软件”，则精臣无义务承担任何责任或向您支付任何赔偿。同时，精臣对您或第三方提供的应用于或操作于本“软件”的内容或与该内容相关的其他内容而引起的任何索赔或损害不承担任何责任。</span><br/>
                <span>六．终止或中断服务</span><br/>
                <span>1.鉴于互联网技术的特殊及复杂性，精臣保留根据平台运营情况修改或中断服务而不需通知您的权利，但保证不会对您和第三方的合法权益产生重大影响。</span><br/>
                <span>2. 如因不可抗力因素、第三方因素及不可预见及控制的因素导致精臣无法继续提供服务，精臣将尽快通知您。</span><br/>
                <span>3.如用户违反本服务协议中的内容，精臣有权取消用户的使用资格，并终止向其继续提供服务。</span><br/>
                <span>七．适用、管辖法律及条款效力</span><br/>
                <span>1. 本《协议》的订立、执行和解释及争议的解决均应适用中国大陆法律。</span><br/>
                <span>2. 如您与精臣就本《协议》内容或其执行发生任何争议，双方应进行友好协商；协商不成时，任何一方均可向精臣住所地有管辖权的人民法院提起诉讼。</span><br/>
                <span>3. 本《协议》的各项条款具有可分割性，如果某一条款被适用法律认定为无效，则其他条款不受影响，应继续有效并执行。</span><br/>
                <span>八．特别提示</span><br/>
                <span className='weight'>1.请您仔细阅读本“软件”的隐私政策，即《精臣隐私保护政策》的内容，该隐私政策是本协议的重要组成部分。您确认接受该政策的一切内容，并保证定期查阅该政策，以随时了解该政策是否发生修改以及修改后的内容。</span><br/>
                <span className='weight'>2.在本平台注册是免费的，精臣愿意尽最大努力通过本“软件”为您提供更多、更优质的功能。您知悉并认可，尽管精臣向您提供本“软件”的现有功能（简称“现有功能”），但精臣未来有可能就本“软件”的一个替换或修改版本或任何升级版本的任何新增、替换或附加功能的全部或部分向您收取费用，具体资费政策以精臣届时公布内容为准，届时您可自行选择是否接受精臣提供的本“软件”的收费功能，如您选择接受，则您应按照精臣届时制定的收费标准支付相关费用。如果精臣针对本“软件”的任何收费功能有任何随附的协议或条款，则您在选择接受该收费功能时，需先行同意此等协议或条款，且此等协议或条款将视为对本《协议》内容之补充，如有冲突，此等协议或条款将优先适用。</span><br/>
                <span className='weight'>3.另外提醒您注意，本《协议》之约定有时可能会更改（包括但不限于直接在本《协议》基础上对本《协议》现有条款的修改、变更、替换、增加、删除以及以补充协议等方式对本《协议》现有条款进行修改、变更、替换、增加、删除），本《协议》关于收集个人信息的目的、方式、范围发生变化时，将会通过弹窗、通知等方式征求您的同意。</span><br/>
                <span className='weight'>4.广告</span><br/>
                <span className='weight'>1）您认可在任何时候精臣均有权利用本“软件”向您展示广告或推销，包括但不限于利用本“软件”为任何第三方发布广告、推广第三方产品及服务或者针对精臣自身产品及服务发布广告或进行推广等。您同意对精臣在任何时候利用本“软件”获取的任何利益（包括但不限于为任何第三方发布广告、推广第三方产品及服务而获得的收入），您均不享有任何权利，精臣在任何时候因本“软件”获取的任何利益均全部归属于精臣所有。</span><br/>
                <span className='weight'>2）精臣利用本“软件”展示、发布广告、进行推销、推广的形式（包括但不限于在本“软件”启动界面展示第三方广告等）、模式和范围可不经向您特别通知而变更。</span><br/>
                <span className='weight'>3）您应意识到本“软件”上展示给您的内容，包括但不限于本“软件”中的广告及推广服务中展示的内容，可能受知识产权的保护。您不得修改、租赁、出租、借贷、出售、分发该内容（无论全部还是部分）或根据该内容创作衍生作品，除非精臣或内容权利人在相关协议、相关业务规则中特别告知用户可以为之。</span><br/>
                <span className='weight'>4）您于本软件或经由本软件与广告商进行通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及前述交易其他任何相关条款、条件、保证或声明，完全为您与前述广告商之间之行为，精臣不承担对广告及广告商进行审查、甄别之义务。</span><br/>
                <span className='weight'>5）前述任何交易或前述广告商出现于本“软件”所生之任何性质的损失或损害，您同意精臣不予负责。</span><br/>
                <span className='weight'>九．本《协议》第八条特别提示条款，效力优先于本《协议》的其他条款，当本《协议》第八条特别提示条款与本《协议》的其他条款相抵触时，以本《协议》第八条特别提示条款为准。</span><br/>
                <span >至此，您肯定已经详细阅读并已理解本《协议》，如同意请按“我同意”或相同意思表示的按钮。</span><br/>
        </p>
            </div >
        )
    }
}