export const SET_INFORFIELD = 'SET_INFORFIELD';
export type SET_INFORFIELD_TYPE = typeof SET_INFORFIELD;
export const GET_INFORFIELD = 'GET_INFORFIELD';
export type GET_INFORFIELD_TYPE = typeof GET_INFORFIELD;

// export const SET_MARKET_TYPE = 'SET_MARKET_TYPE';
// export type SET_MARKET_TYPE_TYPE = typeof SET_MARKET_TYPE;

// export const SET_TRADE_AREA = 'SET_TRADE_AREA';
// export type SET_TRADE_AREA_TYPE = typeof SET_TRADE_AREA;

// export const SET_RETAIL_FORMATS = 'SET_RETAIL_FORMATS';
// export type SET_RETAIL_FORMATS_TYPE = typeof SET_RETAIL_FORMATS;

// export const SET_IS_CHAIN = 'SET_IS_CHAIN';
// export type SET_IS_CHAIN_TYPE = typeof SET_IS_CHAIN;

// export const SET_INFORFIELD = 'SET_INFORFIELD';
// export type SET_INFORFIELD_TYPE = typeof SET_INFORFIELD;

// export const SET_INFORFIELD = 'SET_INFORFIELD';
// export type SET_INFORFIELD_TYPE = typeof SET_INFORFIELD;

// export const SET_INFORFIELD = 'SET_INFORFIELD';
// export type SET_INFORFIELD_TYPE = typeof SET_INFORFIELD;

// export const SET_INFORFIELD = 'SET_INFORFIELD';
// export type SET_INFORFIELD_TYPE = typeof SET_INFORFIELD;