/**
 * @Author: EthanWong
 * @Date: 2020/06/11
 */
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { REQUEST_NOTIFICATION, REQUEST_STATISTICS, UPDATE_NOTIFICATION } from '@/constants/home';
import { HomeModelState } from '@/actions/home';
import { IPageVO } from '@/VO/common';

export interface IHomeConnectProps {
    home: {
        statistics: HomeModelState['data'];
        notification: HomeModelState['notification'];
    }
}

const mapStateToProps = ({ home: { statistics, notification }}: IHomeConnectProps) => ({
    statistics,
    notification
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    queryStatistics: () => dispatch({ type: REQUEST_STATISTICS }),
    queryNotification: (payload: IPageVO) => dispatch({ type: REQUEST_NOTIFICATION, payload }),
    updateNotification: (payload: number) => dispatch({ type: UPDATE_NOTIFICATION, payload })
});

const connectDecorator: Function = connect(
    mapStateToProps,
    mapDispatchToProps,
)

export default connectDecorator;
