import React from 'react';
import numeral from 'numeral';

/**
 * @method format various of text style
 */
type TFormatType = 'dateTime' | 'integer';
export const textFormat = (source: any, format: { name: TFormatType; value?: string; suffix?: string }) => {
    switch (format.name) {
        case 'integer':
            return (<>{numeral(source).format(format.value || '0,0')}{format.suffix}</>);
        default:
            break;
    }
};